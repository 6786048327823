import 'javascripts/flat_2016/index';

function addTargetToWidgetLinks() {
  let links = document.querySelectorAll(".hero__link a");

  if (!links) {
    return;
  }

  links.forEach((link) => {
    link.setAttribute("target", "_blank");
  });
}

document.addEventListener('DOMContentLoaded', () => {
  addTargetToWidgetLinks();
})
